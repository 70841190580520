import { createAction, createAsyncAction } from 'typesafe-actions'
import { InputAuth, UserData } from '../../api/login'
import { AxiosError } from 'axios'


export const LOGIN = "login/LOGIN";
export const LOGIN_SUCCESS = "login/LOGIN_SUCCESS";
export const LOGIN_ERROR = "login/LOGIN_ERROR";

// Save Auth
export const SET_AUTHENTICATED = "login/SET_AUTHENTICATED";
// Delete Auth
export const SET_UNAUTHENTICATED = "login/SET_UNAUTHENTICATED";


export const loginAsync = createAsyncAction(
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR
)<InputAuth, UserData, AxiosError>();

export const setAuthenticated = createAction(SET_AUTHENTICATED)();
export const setUnAuthenticated = createAction(SET_UNAUTHENTICATED)();
