import axios from 'axios';

export async function authRequest(input: InputAuth) { // 만료된 토큰 다시 얻기
    const response = await axios.post<AuthData>(`${process.env.REACT_APP_API_HOST}/api/auth/authtoken`, { email: input.email, password: input.password });
    return response.data;
}
export async function loginRequest(input: InputAuth) {
    if (input.user === "admin") {
        const response = await axios.post<UserData>(`${process.env.REACT_APP_API_HOST}/admin/login/`, { email: input.email, password: input.password });
        return response.data;
    } else {
        const response = await axios.post<UserData>(`${process.env.REACT_APP_API_HOST}/api/login/`, { userID: input.userID, password: input.password });
        return response.data;
    }
}

export interface InputAuth {
    password: string,
    user: string,
    [x: string]: any
}
export interface AuthData {
    uuid: string,
    token: string,
    isAuth: boolean
}
export interface UserData { /// Load User Data
    name: string,
    email: string,
    userID: string,
    token: string,
    [x: string]: any

}
export interface ErrorData {
    statusCode: number,
    msg: string,
}







