import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

const GlobalStyle = createGlobalStyle`
  ${normalize}

  html,
  body {
    background-color:#ffffff;
    overflow-x: hidden;
    font-family: 'Noto Sans KR', sans-serif;
    display:block;
  }
  * {
    box-sizing: border-box;
  }
  //@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
  @import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

`;

export default GlobalStyle



//background-color:#f5f6fa;
