import { DefaultTheme } from "styled-components";

const size = {
    mobile: '500px',
    tablet: '900px',
    laptop: '1200px',
    desktop: '1800px',
}

const DarkTheme: DefaultTheme = {
    mainBackground: `##f5f6fa`,
    // UI SET
    nav_Btn_default: `#ffffff`,
    nav_Btn_focus: `#333`,
    blueTextColor: `#0082f9`,

    container_max_width: `1440px`,
    container_min_width: `800px`,
    section_cointainer_padding: `20px`, // width 100% 기준으로 padding 처리 
    ui_cointainer_margin: `10px`,

    title: `rgba(255,255,255,0.85)`,
    primaryText: `rgba(255,255,255,0.65)`,
    secondaryText: `rgba(255,255,255,0.45)`,
    disable: `rgba(255,255,255,0.25)`,
    border: `rgba(255,255,255,0.15)`,
    divider: `rgba(255,255,255,0.06)`,
    background: `rgba(255,255,255,0.04)`,
    tableHeader: `rgba(255,255,255,0.02)`,
    mobile: `(max-width: ${size.mobile})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    desktop: `(min-width: ${size.desktop})`,
};
const LightTheme: DefaultTheme = {
    mainBackground: `#fff`,
    // neutral color

    // UI SET
    nav_Btn_default: `rgba(255,255,255,0.85)`,
    nav_Btn_focus: `rgba(255,255,255,0.85)`,
    blueTextColor: `#0082f9`,

    container_max_width: `1440px`,
    container_min_width: `1024px`,
    section_cointainer_padding: `20px`, // width 100% 기준으로 padding 처리 
    ui_cointainer_margin: `10px`,

    title: `rgba(0, 0, 0, 0.85)`,
    primaryText: `rgba(0, 0, 0, 0.75)`,
    secondaryText: `rgba(0, 0, 0, 0.45)`,
    disable: `rgba(0, 0, 0, 0.25)`,
    border: `rgba(0, 0, 0, 0.15)`,
    divider: `rgba(0, 0, 0, 0.06)`,
    background: `rgba(0, 0, 0, 0.04)`,
    tableHeader: `rgba(0, 0, 0, 0.02)`,
    mobile: `(max-width: ${size.mobile})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    desktop: `(min-width: ${size.desktop})`,
    // point-color
}


export {
    DarkTheme, LightTheme
};