import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import { login, loginSaga } from './login';
import { data, dataSaga } from './getData'
import search from './search'

const rootReducer = combineReducers({
    login,
    data,
    search
})

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
export function* rootSaga() {
    yield all([loginSaga(), dataSaga()]);
}