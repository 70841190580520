import axios from 'axios';

export async function getDataRequest(input: Input) {

    if (input.method === "post") {
        const response = await axios.post<Response_Data>(`${process.env.REACT_APP_API_HOST}${input.url}`, { data: input.data });
        return response.data
    } else {
        const response = await axios.get<Response_Data>(`${process.env.REACT_APP_API_HOST}${input.url}`, { params: input.params });
        return response.data
    }
}
export interface Input {
    [x: string]: any,
    params?: Object
}
export interface Response_Data {
    data: any
}
export interface ErrorData {
    statusCode: number,
    msg: string,
}

