import { createAsyncAction } from 'typesafe-actions'
import { Input, Response_Data } from '../../api/request'
import { AxiosError } from 'axios'

export const GET_DATA = 'GET_DATA'
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS'
export const GET_DATA_ERROR = 'GET_DATA_ERROR'

export const getDataAsync = createAsyncAction(
    GET_DATA,
    GET_DATA_SUCCESS,
    GET_DATA_ERROR
)<Input, Response_Data, AxiosError>();
