import { loginAsync, LOGIN } from './actions'
import { authRequest, loginRequest } from '../../api/login';
import { takeEvery } from 'redux-saga/effects';
import createAsyncSaga from '../../libs/createAsyncSaga';

const loginReqeustSaga = createAsyncSaga(loginAsync, loginRequest);

export function* loginSaga() {
    yield takeEvery(LOGIN, loginReqeustSaga);
}
