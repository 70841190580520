import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import rootReducer, { rootSaga } from './modules';
import { Provider } from 'react-redux'
import { ThemeProvider } from "styled-components";
import { DarkTheme } from "./styles/theme";
import GlobalStyle from "./styles/global-style";
import './assets/css/Calander.css';
import './assets/css/AntdCustom.css'

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);


function importBuildTarget() {
  if (process.env.REACT_APP_BUILD_TARGET === "papp") {
    return import("./App");
  } else if (process.env.REACT_APP_BUILD_TARGET === "admin") {
    return import("./Admin")
  } else {
    return Promise.reject(
      new Error("No such build target: " + process.env.REACT_APP_BUILD_TARGET)
    );
  }
}


importBuildTarget().then(({ default: Environment }) =>
  ReactDOM.render(
    <ThemeProvider theme={DarkTheme}>
      <Provider store={store}>
        <GlobalStyle />
        <Environment />
      </Provider>
    </ThemeProvider>,
    document.getElementById("root")
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
