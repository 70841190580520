
import { getDataAsync, GET_DATA } from './actions'
import { getDataRequest } from '../../api/request'
import { takeEvery } from 'redux-saga/effects'
import createAsyncSaga from '../../libs/createAsyncSaga';


const getDataSaga = createAsyncSaga(getDataAsync, getDataRequest);
export function* dataSaga() {
    yield takeEvery(GET_DATA, getDataSaga)
}
