import { createReducer } from 'typesafe-actions';
import { LoginState, LoginAction } from './types'
import {
    LOGIN, LOGIN_SUCCESS, LOGIN_ERROR
} from './actions'
import { asyncState } from '../../libs/reducerUtils';
import axios from 'axios';

const initialLoginState: LoginState = {
    useData: asyncState.initial(),
}
//Login
export const login = createReducer<LoginState, LoginAction>(initialLoginState, {
    [LOGIN]: state => ({
        ...state,
        useData: asyncState.load()
    }),
    [LOGIN_SUCCESS]: (state, action) => {
        console.log("success");
        console.log(action.payload);

        localStorage.setItem("token", action.payload.data.token);
        localStorage.setItem("isAuth", "true");
        localStorage.setItem("userID", action.payload.data.userID);
        localStorage.setItem("storeCode", action.payload.data.storeCode);
        localStorage.setItem("name", action.payload.data.name);
        localStorage.setItem("email", action.payload.data.email);
        axios.defaults.headers.common['Authorization'] = action.payload.token;
        return {
            ...state,
            useData: asyncState.success(action.payload),
        }
    },
    [LOGIN_ERROR]: (state, action) => {
        alert(`${action.payload.response?.data.message}`)
        localStorage.removeItem("isAuth");
        localStorage.removeItem("token");
        delete axios.defaults.headers.common['Authorization']
        return {
            ...state,
            useData: asyncState.error(action.payload)
        }
    },
})



