import { createReducer } from 'typesafe-actions'
import { DataState, DataAction } from './types'
import { GET_DATA, GET_DATA_SUCCESS, GET_DATA_ERROR } from './actions'
import { asyncState } from '../../libs/reducerUtils'

const initialState: DataState = {
    responseData: asyncState.initial()
}
export const data = createReducer<DataState, DataAction>(initialState, {
    [GET_DATA]: state => ({
        ...state,
        responseData: asyncState.load()
    }),
    [GET_DATA_SUCCESS]: (state, action) => {
        console.log(action.payload)
        return {
            ...state,
            responseData: asyncState.success(action.payload)
        }
    },
    [GET_DATA_ERROR]: (state, action) => ({
        ...state,
        responseData: asyncState.error(action.payload)
    })

})

