import {
    createAction,
    ActionType,
    createReducer
} from 'typesafe-actions';

export const selectMonth = createAction('search/SELECT_MONTH')<number>();

const actions = { selectMonth };
type SearchAction = ActionType<typeof actions>

type SearchState = {
    month: number;
};

const d = new Date();
const thisMonth = d.getMonth() + 1


const initialState: SearchState = {
    month: thisMonth
}


const search = createReducer<SearchState, SearchAction>(initialState)
    .handleAction(selectMonth, (state, action) => ({
        month: action.payload
    }));

export default search
